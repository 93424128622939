import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import React, { useState } from "react";
import deleteIcon from "../../../../../../assets/svg/delete.svg";
import EditIcon from "../../../../../../assets/svg/edit.svg";
import ActionLink from "../../../../../../components/ActionLink/ActionLink";
import CustomButton from "../../../../../../components/Button/CustomButton";
import DeleteConfirmationDialog from "../../../../../../components/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { socialMediaIcons } from "../../../../../../constants";
import {
  useDeleteSocialMediaLink,
  useGetSocialMediaLinks,
} from "../../../../../../utils/react-query/querySupplier/queries";
import PreferencesModal from "../PreferencesModal/PreferencesModal";
import SocialMediaLinkForm from "./SocialMediaLinkForm";
import styles from "./SocialMediaLinksPreferences.module.scss";

const SocialMediaLinksList = () => {
  const { data: socialMediaLinks } = useGetSocialMediaLinks();
  const { mutateAsync: deleteSocialMediaLink } = useDeleteSocialMediaLink();

  const [openModal, setOpenModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState<SocialMediaLink | null>(
    null
  );
  const [action, setAction] = useState<socialMediaFormType>("create");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const handleOpenAddModal = (action: socialMediaFormType) => {
    setSelectedLink(null);
    setOpenModal(true);
    setAction(action);
  };

  const handleOpenEditModal = (
    action: socialMediaFormType,
    link: SocialMediaLink
  ) => {
    setSelectedLink(link);
    setOpenModal(true);
    setAction(action);
  };

  const onDelete = async (linkId: number) => {
    await deleteSocialMediaLink(linkId);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  return (
    <div>
      <PreferencesModal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title={
          action === "create" ? "Agregar una Red Social" : "Editar Red Social"
        }
      >
        <SocialMediaLinkForm
          socialMedia={selectedLink}
          action={action}
          onClose={() => setOpenModal(false)}
        />
      </PreferencesModal>
      <CustomButton
        color="secondary"
        shape="little-rounded"
        onClick={() => handleOpenAddModal("create")}
        icon={AddOutlinedIcon}
      >
        Agregar Enlace
      </CustomButton>

      <TableContainer className={styles.tableContainer}>
        <Table className={styles.table} size="small" aria-label="a dense table">
          <TableBody>
            {socialMediaLinks?.map((link: SocialMediaLink) => (
              <TableRow key={link.id}>
                <TableCell className={styles.tableCellSocialMedia}>
                  <div>
                    <img
                      src={socialMediaIcons[link.social_media_type_id]}
                      alt={link.social_media_type_name}
                      width={32}
                      height={32}
                    />
                    <span>{link.social_media_type_name}</span>
                  </div>
                </TableCell>
                <TableCell className={styles.tableCellUrl}>
                  <ActionLink href={link.url} text={link.url} />
                </TableCell>
                <TableCell>
                  <div className="preferencesActionButtonContainer">
                    <img
                      src={EditIcon}
                      alt="editIcon"
                      width={30}
                      height={30}
                      onClick={() => handleOpenEditModal("update", link)}
                    />
                    <img
                      src={deleteIcon}
                      alt="deleteIcon"
                      width={30}
                      height={30}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: "Eliminar enlace seleccionado",
                          subTitle: `Estás a punto de eliminar el enlace "${link.social_media_type_name}". ¿Estás seguro de que deseas continuar?`,
                          onConfirm: () => onDelete(link.id),
                        });
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
            <DeleteConfirmationDialog
              confirmDialog={confirmDialog}
              setConfirmDialog={setConfirmDialog}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SocialMediaLinksList;
