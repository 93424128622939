import { Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState } from "react";
import EmptyImage from "../../../../../../assets/svg/empty-banner.svg";
import CustomButton from "../../../../../../components/Button/CustomButton";
import DeleteConfirmationDialog from "../../../../../../components/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { infoToast } from "../../../../../../services/toasts";
import {
  useAddEcommerceBanner,
  useDeleteEcommerceBanner,
} from "../../../../../../utils/react-query/querySupplier/queries";
import { CompanyBanner } from "../../../../types";

type BannerPreferencesProps = {
  ecommBanner: CompanyBanner[];
};

const BannerPreferences = ({ ecommBanner }: BannerPreferencesProps) => {
  const { mutateAsync: addBanner, isLoading: isLoadingAddBanner } =
    useAddEcommerceBanner();
  const { mutateAsync: deleteBanner, isLoading: isLoadingDeleteBanner } =
    useDeleteEcommerceBanner();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const handleAddBanner = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const formData = new FormData();
    const file = event.target.files?.[0];

    if (file) {
      if (file.size > 15 * 1024 * 1024) {
        infoToast(
          "El archivo es demasiado pesado. El tamaño máximo permitido es de 15 MB."
        );
        return;
      }
    }

    if (file) {
      formData.append("companyBanner", file);
    }

    await addBanner({ bannerFile: formData });
  };

  const handleDeleteBanner = async (bannerId: number) => {
    if (!bannerId) return;
    await deleteBanner({ bannerId: bannerId });
  };

  return (
    <div className="banner-section">
      <Paper className="paper-container">
        <div className="banner-section-content">
          <div className="banner-section-header">
            <h1 className="banner-section-title">Banners De Tienda</h1>
            <p className="banner-section-description">
              Aquí puedes gestionar los banners para tu tienda. Puedes cargar
              hasta 4 banners. Asegúrate de que las imágenes tengan las medidas
              adecuadas y no superen el peso máximo permitido. Las dimensiones
              recomendadas para los banners son de 1920x600 píxeles y el tamaño
              máximo del archivo es de 2 MB.
            </p>
          </div>
          <hr className="hr-separator" />

          <div
            className={`${
              ecommBanner.length > 0
                ? "banner-section-add-button"
                : "banner-section-add-button__hidden"
            }`}
          >
            <CustomButton
              color="secondary"
              size="small"
              icon={AddIcon}
              shape="little-rounded"
              isLoading={isLoadingAddBanner}
              disabled={ecommBanner.length >= 4 || isLoadingAddBanner}
              onClick={() => document?.getElementById("add-banner")?.click()}
            >
              Nuevo Banner
            </CustomButton>
            <input
              id="add-banner"
              type="file"
              accept="image/*"
              onChange={handleAddBanner}
              className="banner-section-file-input"
            />
          </div>
          {ecommBanner.length > 0 ? (
            <div className="banner-section-list">
              {ecommBanner.map(({ url, id }) => (
                <div key={id} className="banner-section-item">
                  <DeleteConfirmationDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                  />
                  <img src={url} alt={`Banner ${id}`} />
                  <button
                    disabled={isLoadingDeleteBanner}
                    className="banner-section-delete-button"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Eliminar banner seleccionado",
                        subTitle: `¿Estás seguro de que deseas eliminar este banner?"`,
                        onConfirm: () => handleDeleteBanner(id),
                      });
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="empty-banner">
              <img
                src={EmptyImage}
                alt="Empty"
                className="empty-banner-image"
              />
              <h4 className="empty-banner-title">No hay banners disponibles</h4>
              <p className="empty-banner-description">
                Comienza añadiendo nuevos banners a tu tienda.
              </p>
              <CustomButton
                color="secondary"
                size="small"
                icon={AddIcon}
                shape="little-rounded"
                isLoading={isLoadingAddBanner}
                onClick={() => document?.getElementById("add-banner")?.click()}
              >
                Nuevo Banner
              </CustomButton>
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default BannerPreferences;
