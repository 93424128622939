import { useEffect, useState } from "react";
import { materialTableES } from "../../services/materialTableES.js";

import MaterialTable, { MTableToolbar } from "material-table";
import Modal from "../../components/Modal/Modal";

import ZoomInIcon from "@material-ui/icons/ZoomIn";

import axios from "axios";

function ClientsManagement() {
  const [clients, setClients] = useState([]);
  const [detailData, setDetailData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await axios.get("/v1/clients/admin");
    setClients(res.data);
  };

  const TableHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 10px",
          marginBottom: "1rem",
        }}
      ></div>
    );
  };

  const tableColumns = [
    {
      title: "Fecha de registro",
      type: "date",
      field: "created_at",
    },
    {
      title: "Nombres",
      field: "first_name",
      render: (rowData) => `${rowData.first_name}`,
    },
    {
      title: "Apellidos",
      field: "last_name",
      render: (rowData) => `${rowData.last_name}`,
    },
    { title: "Teléfono", field: "phone" },
    { title: "Correo", field: "email" },
    { title: "Ciudad", field: "city" },
  ];

  return (
    <section className="layout-dashboard-container">
      <div>
        <Modal
          showModal={!!detailData}
          closeModal={() => setDetailData()}
          contentMaxWidth={"400px"}
        >
          <DesignerDetail data={detailData} />
        </Modal>
        <MaterialTable
          title="Clientes"
          columns={tableColumns}
          data={clients}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <TableHeader />
              </div>
            ),
          }}
          localization={materialTableES}
          isLoading={clients.length <= 0}
          actions={[
            {
              icon: () => <ZoomInIcon />,
              tooltip: "Detalle",
              onClick: (event, rowData) => {
                setDetailData(rowData);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            padding: "dense",
            actionsCellStyle: {
              paddingRight: 20,
            },
            exportButton: true,
            pageSizeOptions: [5, 10, 50, 100, 300, 500],
          }}
        />
      </div>
    </section>
  );
}

// POP-UP with professional profile data when loupe icon is clicked
const DesignerDetail = ({ data }) => {
  return (
    <div style={{ padding: "50px", display: "flex", flexDirection: "column" }}>
      <h3 style={{ marginTop: 10 }}>Información básica</h3>
      <span>
        <i>Nombre.</i> {`${data.first_name} ${data.last_name}`}
      </span>
      <span>
        <i>Correo.</i> {data.email}
      </span>
      <span>
        <i>Cédula.</i> {data.cedula}
      </span>
      <span>
        <i>Celular.</i> {data.phone}
      </span>
      <span>
        <i>Dirección.</i> {data.address}
      </span>
      <span>
        <i>Ciudad.</i> {data.city}
      </span>
    </div>
  );
};

export default ClientsManagement;
