import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { type CompanyBanner } from "../../privatePages/supplier/types";

export interface CompanyStoreState {
  companyPrimaryColor: string | null;
  companySecondaryColor: string | null;
  companyName: string | null;
  companyFontType: string | null;
  companyLogo: string | null;
  companyBanner: CompanyBanner[] | [];
  companyNavbarLinks: NavbarLink[] | [];
  companySocialMediaLinks: SocialMediaLink[] | [];
  set: (data: Partial<CompanyStoreState>) => void;
}

const useCompanyStore = create<CompanyStoreState>()(
  persist(
    (set) => ({
      companyPrimaryColor: "",
      companySecondaryColor: "",
      companyFontType: "",
      companyName: "",
      companyLogo: null,
      companyBanner: [],
      companyNavbarLinks: [],
      companySocialMediaLinks: [],
      set: (data: Partial<CompanyStoreState>) => {
        set(data);
      },
    }),
    {
      name: "company",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useCompanyStore;
