import { Divider, Grid, TextField } from "@material-ui/core";

import { useState } from "react";
import deleteIcon from "../../../../../../assets/svg/delete.svg";
import EditIcon from "../../../../../../assets/svg/edit.svg";
import DeleteConfirmationDialog from "../../../../../../components/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { useDeleteNavbarLink } from "../../../../../../utils/react-query/querySupplier/queries";

type NavbarLinksListProps = {
  links: NavbarLink[];
  handleEdit: (link: NavbarLink) => void;
};

const NavbarLinksList = ({ links, handleEdit }: NavbarLinksListProps) => {
  const { mutateAsync: deleteNavbarLink } = useDeleteNavbarLink();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    onConfirm: () => {},
  });

  const onDelete = async (linkId: number) => {
    await deleteNavbarLink(linkId);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };
  return (
    <>
      {links?.map((link: NavbarLink) => (
        <div key={link.id}>
          <Divider variant="fullWidth" style={{ marginBlock: "0.8rem" }} />
          <Grid container spacing={2}>
            <Grid item xs={4} sm={4} md={5}>
              <TextField
                label="Nombre"
                size="small"
                value={link.name}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4} sm={4} md={5}>
              <TextField
                size="small"
                label="URL"
                value={link.url}
                variant="outlined"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2}>
              <div className="preferencesActionButtonContainer">
                <img
                  src={EditIcon}
                  alt="editIcon"
                  width={30}
                  height={30}
                  onClick={() => handleEdit(link)}
                />
                <img
                  src={deleteIcon}
                  alt="deleteIcon"
                  width={30}
                  height={30}
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Eliminar enlace seleccionado",
                      subTitle: `Estás a punto de eliminar el enlace "${link.name}". ¿Estás seguro de que deseas continuar?`,
                      onConfirm: () => onDelete(link.id),
                    });
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ))}
      <DeleteConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default NavbarLinksList;
