import React from "react";
import { NotificationDetail } from "../Notification/Notification";

import Paper from "@material-ui/core/Paper";
import InboxIcon from "@material-ui/icons/Inbox";
import Notification from "../Notification/Notification";
import CircularProgress from "@material-ui/core/CircularProgress";

import axios from "axios";
import styled from "styled-components";

/**
 * Inbox that contains a user's messages.
 */
function Inbox({ notifications, ...props }) {
  const [selectedNotification, setSelectedNotification] = React.useState();
  let loading = props.loading;

  const handleShowDetail = async (item, index, mid) => {
    await axios.patch(`/v1/messages/${mid}`, {
      is_read: true,
    });
    notifications[index].is_read = true;

    setSelectedNotification(item);
  };

  if (loading) {
    return (
      <Paper>
        <Wrapper
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={25} color="primary" />
        </Wrapper>
      </Paper>
    );
  } else {
    return (
      <Paper>
        <Wrapper>
          {!selectedNotification ? (
            notifications.length > 0 ? (
              notifications.map((item, index) => (
                <Notification
                  onClick={() => handleShowDetail(item, index, item.id)}
                  key={item.id_message}
                  title={item.title}
                  is_read={item.is_read}
                  date={item.created_at}
                  text={item.text}
                  attachment={item.attachment}
                />
              ))
            ) : (
              <EmptyInbox>
                <InboxIcon />
                <span>No hay mensajes en tu bandeja de entrada</span>
              </EmptyInbox>
            )
          ) : (
            <NotificationDetail
              onClick={() => setSelectedNotification()} // go back
              notification={selectedNotification}
            />
          )}
        </Wrapper>
      </Paper>
    );
  }
}

const Wrapper = styled.div`
  margin: 1rem 0;
  overflow: auto;
  min-height: 300px;
  height: 1px; // workaround to min-height not being inherited, see https://stackoverflow.com/a/21836870/6206076
`;

const EmptyInbox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: lightgrey;

  .MuiSvgIcon-root {
    font-size: 4rem;
  }
`;

Inbox.propTypes = {};

Inbox.defaultProps = {};

export default Inbox;
