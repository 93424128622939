import { Link } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styles from "./ActionLink.module.scss";

type ActionLinkProps = {
  href: string;
  text: string;
};

const ActionLink = ({ href, text }: ActionLinkProps) => {
  const icon = <OpenInNewIcon fontSize="small" />;

  return (
    <Link href={href} target="_blank" className={styles.actionLink}>
      {icon}
      {text}
    </Link>
  );
};

export default ActionLink;
