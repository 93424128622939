import styled from "styled-components";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { formatNumberToPrice } from "../../helpers/priceFormatting";
import CustomButton from "../Button/CustomButton";

/**
 * Content of the modal that opens once you click on a product
 */
function ProductModal({ product }) {
  const {
    id,
    name,
    brand,
    company_name,
    category,
    color,
    material,
    min_sale_unit,
    units_per_package,
    presentation,
    taxed_price,
    comission_percentage,
    unit_of_measure,
    units_available,
    description,
    weight,
    light_type,
    lamp_type,
    dimensions,
    principal_image,
    ambient_images,
    technical_sheet = [],
    size,
    origin,
    product_content,
  } = product;

  const allImages = [...principal_image, ...ambient_images];

  const originalImages = allImages.filter(
    (image) => image.state === "original"
  );

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: true,
    speed: 1200,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <StyledContainer>
      <Left>
        <LeftContent>
          <div style={{ display: "flex" }}>
            <img id="mobile" src={principal_image} alt="profile" />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0 1rem",
              }}
            >
              <h2>{name}</h2>
              <h4>Marca: {brand}</h4>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "1rem 0",
                maxHeight: "20rem",
                overflow: "auto",
              }}
            >
              {/* TODO: Create a map to show the product details and make the code cleaner. */}
              <span>
                <strong>Proveedor:</strong> {company_name}
              </span>
              <span>
                <strong>Categoría:</strong> {category}
              </span>
              <span>
                <strong>Color:</strong> {color}
              </span>
              {material && (
                <span>
                  <strong>Material:</strong> {material}
                </span>
              )}
              <span>
                <strong>Porcentaje de comisión:</strong> {comission_percentage}{" "}
                %
              </span>
              {/* TODO: Create a list of categories to the Frontend */}
              {min_sale_unit &&
                (category === "Revestimientos" || category === "Pisos") && (
                  <span>
                    <strong>Unidad mínima de venta:</strong> {min_sale_unit} m2
                  </span>
                )}
              <span>
                <strong>Unidades por empaque:</strong> {units_per_package}
              </span>
              {presentation && (
                <span>
                  <strong>Presentación:</strong> {presentation}
                </span>
              )}
              <span>
                <strong>Precio:</strong> {formatNumberToPrice(taxed_price)}
              </span>
              <span>
                <strong>Unidad de medida:</strong> {unit_of_measure}
              </span>
              <span>
                <strong>Inventario:</strong> {units_available}
              </span>
              <span>
                <strong>Descripción:</strong> {description}
              </span>
              {weight && (
                <span>
                  <strong>Peso:</strong> {weight} kg
                </span>
              )}
              {light_type && (
                <span>
                  <strong>Tipo de iluminación:</strong> {light_type}
                </span>
              )}
              {lamp_type && (
                <span>
                  <strong>Tipo de lámpara:</strong> {lamp_type}
                </span>
              )}
              {size && (
                <span>
                  <strong>Talla:</strong> {size}
                </span>
              )}
              {origin && (
                <span>
                  <strong>Origen:</strong> {origin}
                </span>
              )}
              {product_content && (
                <span>
                  <strong>Contenido del producto:</strong> {product_content}
                </span>
              )}
              <span>
                <strong>Dimensiones:</strong>
              </span>
              {dimensions.width && (
                <span>
                  <strong>Ancho:</strong> {dimensions.width} mts
                </span>
              )}
              {dimensions.height && (
                <span>
                  <strong>Altura:</strong> {dimensions.height} mts
                </span>
              )}
              {dimensions.length && (
                <span>
                  <strong>Largo:</strong> {dimensions.length} mts
                </span>
              )}
              {dimensions.depth && (
                <span>
                  <strong>Profundidad:</strong> {dimensions.depth} mts
                </span>
              )}
              {dimensions.thickness && (
                <span>
                  <strong>Espesor:</strong> {dimensions.thickness} cms
                </span>
              )}
              {dimensions.diameter && (
                <span>
                  <strong>Diametro:</strong> {dimensions.diameter} mts
                </span>
              )}
            </div>
          </div>
          {technical_sheet.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: "1",
              }}
            >
              <a
                style={{ textDecoration: "none" }}
                href={technical_sheet[0].url}
                target="__blank"
              >
                <CustomButton color="primary" shape="little-rounded">
                  Ver Ficha técnica
                </CustomButton>
              </a>
            </div>
          )}
        </LeftContent>
      </Left>
      <Right>
        <Slider {...settings}>
          {originalImages.length > 0 ? (
            originalImages.map((image, index) => (
              <img key={index} src={image.url} alt={`producto ${name}`} />
            ))
          ) : (
            <img
              src="https://images.pexels.com/photos/5490197/pexels-photo-5490197.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
              alt="complementary"
            />
          )}
        </Slider>
      </Right>
    </StyledContainer>
  );
}

const dimensionsES = {
  width: "ancho (mts)",
  height: "alto (mts)",
  depth: "profundidad (mts)",
  length: "longitud (mts)",
  weight: "peso (kg)",
};

const StyledContainer = styled.div`
  background-color: #f2f2f2;

  #desktop {
    display: none;
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 850px) {
    max-height: 700px;

    .slick-slider,
    .slick-track,
    .slick-list,
    .slick-slide div,
    .slick-slide div img {
      outline: none;
      height: 100%;
    }

    display: flex;
    width: 100%;

    #mobile {
      display: none;
    }

    #desktop {
      margin: 0 1rem;
      display: block;
    }
  }
`;

const Left = styled.div`
  padding: 1rem;

  @media (min-width: 850px) {
    width: 100%;
  }
`;

const LeftContent = styled.div`
  font-family: "Open Sans", sans-serif;
  height: 100%; // test this on mobile
  display: flex;
  flex-direction: column;

  h1 {
    color: #333333;
    font-size: 2.5rem;
    margin: 0;
  }

  p {
    margin: 0;
    color: grey;
    text-align: justify;
  }

  img {
    width: 100px;
    height: 150px;
    object-fit: cover;
  }
`;

const Right = styled.div`
  width: 100%;
  background-color: transparent;

  @media (min-width: 850px) {
    min-width: 30%;
  }
`;

ProductModal.defaultProps = {};

export default ProductModal;
