import React from "react";
import styled from "styled-components";

import successIcon from "../../assets/svg/success.svg";
// import successIcon from "../../assets/svg/success3.svg";

/**
 * Container displaying a success message and a svg icon.
 */
function SuccessBox({ title, text, children, ...props }) {
  return (
    <StyledContainer>
      <img src={successIcon} alt="success icon" />
      <h1>{title}</h1>
      <p>{text}</p>
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
    background-color: ${(props) => props.backgroundColor};
		text-align: center;

		img {
			height: 128px;
			width: 128px;
			margin-bottom: 2rem;
			filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
		}
    
		h1 {
			font-weight: normal;
			margin: 0;
			color: #333333;
			font-size: 1.25rem;

			@media (min-width: 768px) {
				font-size: 1.5rem;
			}
		};

		p {
			font-family: "Open Sans", sans-serif;
			color: grey;
		;

  @media (min-width: 768px) {
		h1 {
				font-size: 1.5rem;
		}
  }
`;

SuccessBox.defaultProps = {
  backgroundColor: "#FFF",
};

export default SuccessBox;
