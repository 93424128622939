import { yupResolver } from "@hookform/resolvers";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import CustomButton from "../../../../../../components/Button/CustomButton";
import { socialLinkSchema } from "../../../../../../services/formValidation";
import {
  useCreateSocialMediaLink,
  useGetSocialMedia,
  useUpdateSocialMediaLink,
} from "../../../../../../utils/react-query/querySupplier/queries";

type SocialLinkFormProps = {
  socialMedia: SocialMediaLink | null;
  action: "update" | "create";
  onClose: () => void;
};

const SocialMediaLinkForm = ({
  socialMedia,
  action,
  onClose,
}: SocialLinkFormProps) => {
  const { data: socialMediaOptions, isLoading: isLoadingSocialMedia } =
    useGetSocialMedia();
  const {
    mutateAsync: createSocialMediaLink,
    isLoading: isCreatingSocialMediaLink,
  } = useCreateSocialMediaLink();
  const {
    mutateAsync: updateSocialMediaLink,
    isLoading: isUpdatingSocialMediaLink,
  } = useUpdateSocialMediaLink();

  const formattedSocialMediaOptions =
    socialMediaOptions?.map((media: { id: number; name: string }) => ({
      value: media.id,
      label: media.name,
    })) || [];

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(socialLinkSchema),
    defaultValues: {
      socialMediaTypeId: socialMedia ? socialMedia.social_media_type_id : null,
      url: socialMedia ? socialMedia.url : "",
    },
  });

  const onSubmit = async (data: SocialMediaLinkFormData) => {
    if (action === "update" && socialMedia) {
      await updateSocialMediaLink({
        id: socialMedia.id,
        socialMediaTypeId: data.socialMediaTypeId,
        url: data.url,
      });
    } else {
      await createSocialMediaLink({
        socialMediaTypeId: data.socialMediaTypeId,
        url: data.url,
      });
    }
    onClose();
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Controller
            name="socialMediaTypeId"
            control={control}
            render={({ ...field }) => (
              <FormControl fullWidth margin="normal">
                <Select
                  isDisabled={action === "update"}
                  isLoading={isLoadingSocialMedia}
                  placeholder="Selecciona una Red Social"
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: "56px",
                      minHeight: "56px",
                      borderColor: errors.socialMediaTypeId
                        ? "red"
                        : base.borderColor,
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  menuPortalTarget={document.body}
                  {...field}
                  options={formattedSocialMediaOptions}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption?.value);
                  }}
                  value={formattedSocialMediaOptions.find(
                    (option: any) => option.value === field.value
                  )}
                />
                {errors && (
                  <FormHelperText style={{ color: "red" }}>
                    {errors.socialMediaTypeId?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="url"
            control={control}
            render={({ onChange, onBlur, value, name, ref }) => (
              <TextField
                size="small"
                label="URL"
                variant="outlined"
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                error={!!errors.url}
                helperText={errors?.url?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomButton
            type="submit"
            color="secondary"
            shape="little-rounded"
            block
            isLoading={isCreatingSocialMediaLink || isUpdatingSocialMediaLink}
          >
            {action === "update" ? "Guardar cambios" : "Agregar Enlace"}
          </CustomButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SocialMediaLinkForm;
