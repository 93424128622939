import { Paper } from "@material-ui/core";
import React from "react";
import SocialMediaLinksList from "./SocialMediaLinksList";

function SocialMediaLinksPreferences() {
  return (
    <Paper className="paper-container">
      <div className="banner-section-header">
        <h1 className="banner-section-title">
          Gestión de Enlaces a Redes Sociales
        </h1>
        <p className="banner-section-description">
          En esta sección, puedes administrar los enlaces de tus redes sociales
          que serán visibles en tu sitio web. Puedes agregar nuevas redes
          sociales, editar enlaces existentes o eliminar aquellos que ya no sean
          relevantes. Asegúrate de que cada enlace esté correctamente
          configurado para dirigir a la página adecuada de la red social
          correspondiente.
        </p>
      </div>
      <hr className="hr-separator" />
      <SocialMediaLinksList />
    </Paper>
  );
}

export default SocialMediaLinksPreferences;
