import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import { FC } from "react";
import CustomButton from "../Button/CustomButton";

interface DeleteConfirmationDialogProps {
  confirmDialog: {
    isOpen: boolean;
    title: string;
    subTitle: string;
    onConfirm?: () => void;
  };
  setConfirmDialog: any;
}

const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
  confirmDialog,
  setConfirmDialog,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={confirmDialog.isOpen}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReportProblemRoundedIcon
            style={{ color: "#EC7063", fontSize: "30px", marginRight: "10px" }}
          />
          <h4 className="subtitle-1">{confirmDialog.title}</h4>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="body-2">
          {confirmDialog.subTitle}
        </DialogContentText>
      </DialogContent>
      <hr className="hr-separator" />
      <DialogActions>
        <CustomButton
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
          color="outlined"
          shape="little-rounded"
          size="small"
        >
          No, Cancelar
        </CustomButton>
        <CustomButton
          onClick={confirmDialog.onConfirm}
          color="danger"
          shape="little-rounded"
          size="small"
        >
          Si, Eliminar !
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
