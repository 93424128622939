import axios from "axios";
import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { materialTableES } from "../../../../services/materialTableES";
import Switch from "@material-ui/core/Switch";
import { formatNumberToPrice } from "../../../../helpers/priceFormatting";
import { errorToast, successToast } from "../../../../services/toasts";
import { handleError } from "../../../../utils/utils";
import ItemActionsMenu from "./ItemActionsMenu";

function ProductTable() {
  const [data, setData] = useState();

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  const getProducts = async () => {
    let res = await axios.get(`/v2/suppliers/products`);
    setData(res.data);
  };

  const handleApprovalChange = async (rowData) => {
    let isPublic = !rowData.is_public;
    let copyData = [...data];
    let index = copyData.indexOf(rowData);
    copyData[index].is_public = isPublic;
    setData(copyData);

    try {
      const res = await axios.patch(`/v2/suppliers/products/${rowData.product_id}/public`, {
        is_public: isPublic,
      });
      if (res.status === 200) {
        const action = isPublic ? "activado" : "desactivado";
        const successMessage = `El producto ha sido ${action} con éxito.`;
        successToast(successMessage);
      }
    } catch (error) {
      handleError(error);
      errorToast("No se pudo actualizar el producto.");
    }   
  };

  const handleOfferChange = async (rowData) => {
    let isFeatured = !rowData.is_featured;
    let copyData = [...data];
    let index = copyData.indexOf(rowData);
    copyData[index].is_featured = isFeatured;
    setData(copyData);

    try {
      const res = await axios.patch(`/v2/suppliers/products/${rowData.product_id}/featured`, {
        is_featured: isFeatured,
      });
      if (res.status === 200) {
        const action = isFeatured ? "en oferta" : "sin oferta";
        const successMessage = `El producto ahora se encuentra ${action}`;
        successToast(successMessage);
      }
    } catch (error) {
      handleError(error);
      errorToast("No se pudo actualizar el producto.");
    }
  };

  const handleRowDelete = async (id) => {
    const dataDelete = [...data];
    const index = id;
    try {
      const deletedProdcut = await axios.delete(`/v1/products/${id}`);

      if (deletedProdcut.status === 200) {
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        successToast("Producto eliminado con éxito.");
      }
    } catch (error) {
      handleError(error);
      errorToast("No se pudo eliminar el producto.");
    }
  };

  return (
    <div>
      <MaterialTable
        title={""}
        columns={[
          {
            title: "Activo",
            field: "is_public",
            editable: "never",
            render: (rowData) => (  
              <Switch
                checked={rowData.is_public}
                onChange={() => handleApprovalChange(rowData)}
                color="primary"
              />
            ),
          },
          {
            title: "En oferta",
            field: "is_featured",
            editable: "never",
            render: (rowData) => (
              <Switch
                checked={rowData.is_featured}
                onChange={() => handleOfferChange(rowData)}
                color="primary"
              />
            ),
          },
          {
            title: "Imagen",
            field: "image",
            editable: "never",
            render: (rowData) => {
              if (!rowData?.principal_image[0]?.url) {
                return null;
              }
              return (
                <img
                  alt="producto"
                  src={rowData?.principal_image[0]?.url}
                  style={{
                    width: 50,
                    height: 50,
                    objectFit: "cover",
                    borderRadius: "6px",
                  }}
                />
              );
            },
          },
          {
            title: "Nombre Producto",
            field: "name",
            editable: "never",
          },
          {
            title: "Precio",
            field: "taxed_price",
            type: "float",
            render: (rowData) => {
              return formatNumberToPrice(rowData.taxed_price) + " COP";
            },
          },

          {
            title: "Inventario",
            field: "units_available",
          },

          {
            title: "Comisión",
            field: "comission_percentage",
            lookup: {
              20: "20%",
              25: "25%",
              30: "30%",
              35: "35%",
              40: "40%",
              45: "45%",
              50: "50%",
              55: "55%",
              60: "60%",
            },
          },
          {
            title: "Categoría",
            field: "category",
          },
          {
            title: "",
            field: "",
            render: (rowData) => (
              <ItemActionsMenu
                item={rowData.product_id}
                editRoute={`/proveedor/producto/${rowData.product_id}/editar`}
                onDelete={handleRowDelete}
              />
            ),
          },
        ]}
        data={data}
        actions={[]}
        components={{}}
        isLoading={data ? false : true}
        options={{
          actionsColumnIndex: -1,
          padding: "dense",
          headerStyle: {
            backgroundColor: "#f4f6f8",
            color: "#637381",
            fontSize: "14px",
            fontWeight: 600,
            height: "4rem",
          },
          rowStyle: {
            fontSize: "14px",
            fontWeight: 400,
            color: "#1C252E",
          },
          searchFieldStyle: {
            marginBlock: "1rem",
          },
          searchFieldVariant: "outlined",
        }}
        localization={materialTableES}
      />
    </div>
  );
}

export default ProductTable;