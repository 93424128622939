import { Dialog, DialogContent } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { ReactNode } from "react";
import styles from "./PreferencesModal.module.scss";

type PreferencesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  children?: ReactNode;
  buttonText?: string;
  image?: string;
  buttonIcon?: string;
};

const PreferencesModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  image,
}: PreferencesModalProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} disableEscapeKeyDown={false}>
      <DialogContent className={styles.modalContainer}>
        <IconButton
          size="small"
          className={styles.closeButton}
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <div className={styles.modalContent}>
          {image && (
            <div className={styles.modalImage}>
              <img src={image} alt="icon-modal" width={72} height={72} />
            </div>
          )}
          <h1 className={styles.modalTitle}>{title}</h1>
          {subtitle ? <p className="body-2">{subtitle}</p> : null}
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PreferencesModal;
