import { yupResolver } from "@hookform/resolvers";
import { Paper } from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { navbarLinksFormSchema } from "../../../../../../services/formValidation";
import {
  useCreateNavbarLink,
  useGetNavbarLinks,
} from "../../../../../../utils/react-query/querySupplier/queries";
import PreferencesModal from "../PreferencesModal/PreferencesModal";
import NavbarLinksEditForm from "./NavbarLinksEditForm";
import NavbarLinksForm from "./NavbarLinksForm";
import NavbarLinksList from "./NavbarLinksList";

const MAX_NAVBAR_LINKS = 10;

const NavbarLinksPreferences = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState<NavbarLink | null>(null);

  // Query
  const { data: links } = useGetNavbarLinks();
  const { mutateAsync: createLink, isLoading: isCreatelinkLoading } =
    useCreateNavbarLink();

  const { control, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(navbarLinksFormSchema),
    defaultValues: {
      name: "",
      url: "",
    },
  });

  const onSubmit = async (data: NewNavbarLink) => {
    await createLink(data);
    // Clear form fields after submit
    reset();
  };

  const handleEdit = (link: NavbarLink) => {
    if (link) {
      //Stores the selected link
      setSelectedLink(link);
      // open the modal
      setIsModalOpen(true);
    }
  };

  // Check if links are equal or more than the maximum allowed
  const isMaxLinksReached = links?.length >= MAX_NAVBAR_LINKS;

  return (
    <div>
      <PreferencesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Editar URL del Menú"
        subtitle=" Actualiza el nombre y la URL del enlace para reflejar los cambios en
          el menú de navegación."
      >
        <NavbarLinksEditForm
          link={selectedLink!}
          onClose={() => setIsModalOpen(false)}
        />
      </PreferencesModal>
      <Paper className="paper-container">
        <div className="banner-section-header">
          <h1 className="banner-section-title">Gestionar Enlaces del Navbar</h1>
          <p className="banner-section-description">
            Agrega, edita o elimina fácilmente los enlaces que aparecerán en la
            barra de navegación de tu sitio web. Asegúrate de que cada enlace
            esté correctamente etiquetado y tenga una URL válida. Puedes agregar
            hasta un máximo de 10 enlaces.
          </p>
        </div>
        <hr className="hr-separator" />
        <div>
          <NavbarLinksForm
            onSubmit={handleSubmit(onSubmit)}
            control={control}
            errors={errors}
            isLoading={isCreatelinkLoading}
            isDisabled={isMaxLinksReached}
          />
          <NavbarLinksList links={links} handleEdit={handleEdit} />
        </div>
      </Paper>
    </div>
  );
};

export default NavbarLinksPreferences;
